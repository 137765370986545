<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <div class="grid grid-cols-1-auto pa-1" style="align-items: center">
          <div class="headerTitle">
            Solicitação de Saque
          </div>
          <div>
            <v-btn icon @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="px-5 pb-5">
          <div>
            <div class="label">Informa sua chave PIX</div>
            <input
              v-model="include.chave"
              placeholder="Telefone, CPF, email, etc"
              class="inputDefault"
            />
            <!-- <v-text-field
              label="Informa sua chave pix"
              
              outlined
            ></v-text-field> -->
          </div>
          <div class="mt-2">
            <div class="label">Informações adicionais</div>
            <textarea
              v-model="descricaoTemp"
              class="inputDefault"
              placeholder="Exemplo: o nome de quem irá receber o saque"
              rows="3"
            />
          </div>
          <div class="descricaoDoModal">
            <p class="warningMessage">
              Atenção
            </p>
            <p>Essa operação tem uma tarifa de 30% sobre o valor.</p>
            <p>
              O valor total a ser sacado é de {{ getTotalValue }}, já descontado
              a tarifa.
            </p>
            <p>
              O dinheiro estará na sua conta em até no máximo 2 dias úteis.
            </p>
          </div>
          <div class="text-right">
            <v-btn
              @click="solicitarSaque()"
              depressed
              color="primary"
              :loading="loading"
              >Solicitar saque</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ServiceSolicitacaoPagamento from "@/middleware/solicitacoesDePagamento";
export default {
  data() {
    return {
      dialog: true,
      loading: false,
      descricaoTemp: "",
      include: {
        chave: "",
        descricao: "",
        turmaId: null
      },
      valorFinal: null
    };
  },
  mounted() {
    this.include.turmaId = this.$store.getters["aluno/getTurmaAtiva"];
    this.calcTotalValue();
  },
  computed: {
    getTotalValue() {
      let valorFinal = this.valorFinal;
      return Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(valorFinal);
    }
  },
  methods: {
    mountMensagem() {
      this.include.descricao =
        `CHAVE PIX: ${this.include.chave} \n` + this.descricaoTemp;
      return this.include.descricao;
    },
    calcTotalValue() {
      let valorTotal = this.$store.getters["aluno/getSaldoBilhetes"];
      let desconto = valorTotal * 0.3;
      this.valorFinal = valorTotal - desconto;
    },
    solicitarSaque() {
      this.loading = true;

      const payload = {
        turmaId: this.include.turmaId,
        chave: this.include.chave,
        descricao: this.mountMensagem(),
        tipo: "SAQUE_FCZ"
      };
      ServiceSolicitacaoPagamento.solicitarNovoPagamento(payload)
        .then(() => {
          this.$root.sucessoModal("Solicitação efetuada com sucesso!");
          this.$emit("closeAndUpdate");
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
          this.$root.errorModal(err.data);
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.warningMessage {
  font-weight: 600;
  font-size: 15px;
}
.label {
  font-size: 14px;
  color: #7e7e7e;
  margin-bottom: 4px;
}
.inputDefault {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  color: rgba(0, 0, 0, 0.8);
  &:focus {
    outline: 2px solid rgba(0, 100, 150, 0.3);
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
  }
}
.descricaoDoModal {
  padding: 10px;
  background-color: rgba(200, 126, 0, 0.1);
  border-radius: 6px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(200, 126, 0, 0.2);
  color: rgba(50, 50, 50, 1);
  & > p {
    padding: 1px;
    margin: 0px;
  }
}
.headerTitle {
  padding-left: 12px;
  padding-top: 5px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(55, 68, 126, 1);
}
</style>
