var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.permissaoDeAcessoAoMenu)?_c('v-menu',{attrs:{"offset-y":"","min-width":"auto","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',_vm._g(_vm._b({attrs:{"white":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,false,2524810596)},[_c('div',{staticStyle:{"width":"100%","background-color":"white"}},[(_vm.getPermissaoUserAuth == 'ADMIN')?_c('div',{staticClass:"itemSelectMenu",on:{"click":function($event){return _vm.$emit('abrirContribuicoes')}}},[_vm._v(" Contribuições ")]):_vm._e(),_c('div',{staticClass:"itemSelectMenu",on:{"click":function($event){return _vm.abrirModalConfirm('reenviarEmail')}}},[_vm._v(" Reenviar convite ")]),(
          _vm.getParticipanteRole !== 'COMISSAO' &&
            _vm.getParticipanteRole !== 'ADMIN' &&
            _vm.getParticipanteConvite == 'AUTORIZADO'
        )?_c('div',{staticClass:"itemSelectMenu",on:{"click":function($event){return _vm.abrirModalConfirm('setComissao')}}},[_vm._v(" Adicionar comissário ")]):_vm._e(),(
          _vm.getParticipanteRole !== 'COMISSAO' &&
            _vm.getParticipanteRole !== 'ADMIN' &&
            _vm.getParticipanteConvite == 'AUTORIZADO'
        )?_c('div',{staticClass:"itemSelectMenu",on:{"click":function($event){return _vm.abrirModalConfirm('setAdmin')}}},[_vm._v(" Adicionar administrador ")]):_vm._e(),(
          _vm.getParticipanteConvite == 'NAO_AUTORIZADO' ||
            _vm.getParticipanteConvite == 'PENDENTE'
        )?_c('div',{staticClass:"itemSelectMenu",on:{"click":function($event){return _vm.abrirModalConfirm('aceitarAluno')}}},[_vm._v(" Ativar Participante ")]):_vm._e(),_c('div',{staticClass:"lineMenu"}),(_vm.getParticipanteRole == 'COMISSAO')?_c('div',{staticClass:"itemSelectMenu",on:{"click":function($event){return _vm.abrirModalConfirm('removeComissao')}}},[_vm._v(" Remover comissário ")]):_vm._e(),(_vm.getParticipanteConvite == 'AUTORIZADO')?_c('div',{staticClass:"itemSelectMenu",on:{"click":function($event){return _vm.abrirModalConfirm('desativarAluno')}}},[_vm._v(" Desativar usuário ")]):_vm._e(),(_vm.getPermissaoUserAuth == 'ADMIN')?_c('div',{staticClass:"itemSelectMenu",on:{"click":function($event){return _vm.abrirModalConfirm('cancelarContrato')}}},[_vm._v(" Cancelar contrato ")]):_vm._e(),(_vm.permissaoParaExcluirParticipante)?_c('div',{staticClass:"itemSelectMenu",on:{"click":function($event){return _vm.abrirModalConfirm('excluirParticipante')}}},[_vm._v(" Excluir Participante ")]):_vm._e()])]):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400","persistent":""},model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c('v-card',{staticClass:"pa-4"},[_c('div',[_vm._v(_vm._s(_vm.mensagemModal))]),_c('div',{staticClass:"grid grid-cols-2 mt-5",staticStyle:{"justify-items":"center","align-items":"center"}},[_c('div',[_c('v-btn',{attrs:{"color":"white","depressed":""},on:{"click":function($event){return _vm.closeModalConfirm()}}},[_vm._v(" Cancelar ")])],1),_c('div',[_c('v-btn',{attrs:{"color":"red","dark":"","depressed":""},on:{"click":function($event){return _vm.confirmAcao()}}},[_vm._v(" Confirmar ")])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }