<template>
  <div>
    <div>
      <div v-if="getAceitouTermo">
        <div v-if="concurso" class="text-center mt-6">
          <img class="bannerConcurso" :src="concurso.imagem" />
        </div>
        <div class="text-right px-4 pb-2 pt-3 d-flex">
          <div>
            <button class="btnCustom" @click="clickContratoAdesao()">
              Contrato de Adesão
            </button>
          </div>
          <div>
            <button class="btnCustom" @click="clickRegulamentos()">
              Regulamentos
            </button>
          </div>
        </div>
        <vTitulo>Bilhetes</vTitulo>
        <div class="cardInfo">
          <div class="tituloCopiaLink">
            Copie e compartilhe este link para enviar para as pessoas fazerem
            elas mesmas as contribuições em troca dos cupons da promoção
          </div>

          <div class="containCopy">
            <div>
              <div class="urlDesign">
                {{ getSellerURL() }}
              </div>
            </div>
            <div>
              <button class="btnCopy" @click="copyTextShared()">
                Copiar o link para compartilhar
              </button>
            </div>
          </div>
        </div>

        <v-card class="cardBilhetes">
          <div class="grid grid-cols-2 text-left">
            <div class="containerSaldo">
              <div class="pa-2">
                <div class="saldoTitulo">Saldo em conta</div>
                <div class="saldoValor primary--text">
                  {{ getTotalAccount | currency }}
                </div>
              </div>
              <div class="pa-2">
                <button
                  class="btnSaque"
                  :disabled="getTotalAccount === 0"
                  @click="abrirModalSaque()"
                >
                  Saque total
                </button>
              </div>
            </div>
            <div class="text-right">
              <v-btn
                depressed
                color="primary"
                class="btnContribuicao"
                @click="adquirirBilhete()"
              >
                Contribuições e Cupons
              </v-btn>
            </div>
          </div>
          <v-data-table
            :headers="headersFaturas"
            :items="listTickets"
            :items-per-page="itemsPerPage"
            :loading="loadingTickets"
            :server-items-length="pagination.totalElements"
            :options.sync="options"
            loading-text="carregando..."
            class="elevation-0"
            item-key="cpfComprador"
            :footer-props="{
              itemsPerPageOptions: [5, 20, 50, 100]
            }"
          >
            <template v-slot:no-data> Não existe faturas registradas </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="defaultColumn fontRoboto">
                    {{ item.dataCompra | ItsNullDate }}
                  </td>
                  <td class="defaultColumn fontRoboto">
                    {{ item.nomeComprador }}
                  </td>
                  <td class="defaultColumn fontRoboto">
                    {{ item.cpfComprador }}
                  </td>
                  <td class="defaultColumn fontRoboto">
                    {{ item.valor | currency }}
                  </td>
                  <td class="defaultColumn fontRoboto">
                    <statusPagamento :status="item.status" />
                  </td>
                  <td
                    class="defaultColumn fontRoboto"
                    style="text-align: center"
                  >
                    <div v-if="item.status == 'PAGO'">
                      <v-btn
                        color="secondary"
                        x-small
                        @click="onClickVerBilhetes(item)"
                        >Ver bilhetes</v-btn
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <!--  rounded-lg elevation-2  -->
      <div class="pa-4 mt-4 mx-4 white rounded-lg " v-else>
        <custo-zero
          @confirm="confirmarParticipacao()"
          :isModal="false"
          :hasButton="true"
        ></custo-zero>
      </div>
      <div style="height: 30px"></div>
    </div>
    <div v-if="dialogNovoBilhete">
      <ComprarBilhetes @close="closeBilhete" />
    </div>
    <div v-if="dialogVerBilhetes">
      <ver-bilhetes
        :venda="dialogViewVenda"
        @close="closeViewBilhetes"
      ></ver-bilhetes>
    </div>
    <vModalSaqueViaPix
      @closeAndUpdate="closeAndUpdateModalSaque"
      @close="closeModalSaque"
      v-if="modalSaque"
    ></vModalSaqueViaPix>
  </div>
</template>

<script>
import custoZero from "@/components/custoZero.vue";
import { URL_CUSTO_ZERO } from "@/constants/base";
import ContratosURL from "@/constants/contratos";
import ServiceBilhetes from "@/middleware/bilhetes";
import ServiceCustoZero from "@/middleware/custozero";
import moment from "moment";
import ComprarBilhetes from "./ComprarBilhetes.vue";
import statusPagamento from "./StatusPagamento.vue";
import verBilhetes from "./verBilhetes.vue";
import vModalSaqueViaPix from "./vModelSaqueViaPix.vue";

export default {
  components: {
    custoZero,
    ComprarBilhetes,
    verBilhetes,
    statusPagamento,
    vModalSaqueViaPix
  },
  data() {
    return {
      concurso: {},
      dialogNovoBilhete: false,
      dialogVerBilhetes: false,
      dialogViewVenda: null,
      modalSaque: false,
      listTickets: [],
      pagination: {
        totalElements: 0
      },
      options: {},
      itemsPerPage: 5,
      page: 0,
      pageCount: 0,
      loadingTickets: false,
      qtdBilhetes: 0,
      headersFaturas: [
        { text: "Dt. Compra", value: "dataCompra", align: "left" },
        { text: "Nome contribuinte", value: "nomeComprador", align: "left" },
        { text: "CPF contribuinte", value: "cpfComprador", align: "left" },
        { text: "Valor", value: "valor", align: "left" },
        { text: "Status", value: "status", align: "left" },
        { text: "Opções", value: "", align: "right" }
      ],
      sorteioDescricao: null
    };
  },
  mounted() {
    this.buscarBilhetes();
    this.buscarInformacoesDoConcurso();
  },
  watch: {
    options: {
      handler() {
        this.paginarBilhetes();
      },
      deep: true
    }
  },
  filters: {
    ItsNullDate: function(snap) {
      if (snap == null) {
        return "S/Registro";
      } else {
        return moment(snap).format("DD/MM/YYYY");
      }
    }
  },
  methods: {
    closeAndUpdateModalSaque() {
      this.$store.dispatch("aluno/REQUEST_USER");
      this.modalSaque = false;
    },
    closeModalSaque() {
      this.modalSaque = false;
    },
    abrirModalSaque() {
      if (this.getTotalAccount === 0) {
        return;
      }
      this.modalSaque = true;
    },
    clickRegulamentos() {
      window.open(ContratosURL.regulamentoFCZ, "_regulamento_fcz");
    },
    clickContratoAdesao() {
      window.open(ContratosURL.adesaoFCZ, "_contrato_adesao_fcz");
    },
    getSellerURL() {
      let userCPF = this.$store.getters["aluno/getUser"].cpf;
      let transformCPF = userCPF.replace(/\D/g, "");
      return `${URL_CUSTO_ZERO}/contribuir/${transformCPF}`;
    },
    buscarInformacoesDoConcurso() {
      this.initialLoading = true;

      ServiceBilhetes.getConcurso()
        .then(resp => {
          this.sorteioDescricao = resp.data.nome;
          this.concurso = resp.data;
        })
        .catch(err => {
          console.error(err);
        });
    },
    copyTextShared() {
      let linkSeller = this.getSellerURL();
      let sorteioDescription = this.sorteioDescricao.replace("Sorteio de ", "");
      const textoPadrao = `Olá! \n \nEstou buscando apoio voluntário para tornar minha formatura ainda mais especial e gostaria de oferecer a oportunidade de participar de um sorteio incrível! *Ter a chance de ganhar ${sorteioDescription}.* \n\nAgradeço imensamente pelo seu apoio! \n\nÉ simples participar: basta *clicar no link abaixo, seguir os passos e realizar sua contribuição via Pix,* tudo dentro da plataforma. \n\n${linkSeller}`;

      const self = this;
      navigator.clipboard.writeText(textoPadrao).then(
        function() {
          self.$root.sucessoModal("Copiado com sucesso");
        },
        function(err) {
          console.error(err);
          self.$root.errorModal(
            "Erro ao copiar, verifique se seu navegador está atualizado"
          );
        }
      );
    },
    onClickVerBilhetes(venda) {
      if (venda) {
        this.dialogViewVenda = venda;
        this.dialogVerBilhetes = true;
      }
    },
    closeViewBilhetes() {
      this.dialogVerBilhetes = false;
      this.dialogViewVenda = null;
    },
    buscarBilhetes() {
      let self = this;
      this.page = 0;
      this.pageCount = 0;

      const payload = {
        page: 0,
        size: this.itemsPerPage
      };
      ServiceBilhetes.paginarBilhetes(payload)
        .then(resp => {
          self.listTickets = resp.data.content;
          self.pageCount = resp.data.totalPages;
          self.pagination.totalElements = resp.data.totalElements;
        })
        .catch(err => {
          console.error(err);
        });
    },
    paginarBilhetes() {
      let self = this;
      const payload = {
        page: this.options.page - 1,
        size: this.options.itemsPerPage
      };
      ServiceBilhetes.paginarBilhetes(payload)
        .then(resp => {
          self.listTickets = resp.data.content;
          self.pageCount = resp.data.totalPages;
          self.pagination.totalElements = resp.data.totalElements;
          //this.qtdBilhetes = resp.data.totalElements;
        })
        .catch(err => {
          console.error(err);
        });
    },
    adquirirBilhete() {
      this.dialogNovoBilhete = true;
    },
    closeBilhete() {
      this.buscarBilhetes();
      this.dialogNovoBilhete = false;
    },
    confirmarParticipacao() {
      ServiceCustoZero.concordarCustoZero()
        .then(() => {
          this.$store.commit("aluno/setAceiteFCZ", true);
          this.$store.commit("aluno/setExibirFCZ", false);
        })
        .catch(error => {
          console.error(error);
          this.$root.sucessoModal(error);
        });
    }
  },
  computed: {
    getContratoRegulamento() {
      return ContratosURL.regulamentoFCZ;
    },
    getContratoAdesao() {
      return ContratosURL.adesaoFCZ;
    },
    getTotalAccount() {
      return this.$store.getters["aluno/getSaldoBilhetes"];
    },
    getAceitouTermo() {
      const payload = this.$store.getters["aluno/getUserFCZ"];
      return payload.aceiteFCZ;
    }
  }
};
</script>

<style lang="scss" scoped>
.containerSaldo {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
.btnSaque {
  background-color: rgb(114, 117, 255, 0.1);
  text-transform: uppercase;
  border: none;
  color: rgb(93, 95, 255);
  padding: 8px 10px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    background-color: rgb(114, 117, 255, 0.2);
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(150, 150, 150, 1);
  }
}
.btnCustom {
  background-color: rgb(114, 117, 255, 1);
  border: 1px solid rgb(114, 117, 255, 0.2);
  //color: rgb(114, 117, 255);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    //border: 1px solid rgb(114, 117, 255, 0.8);
    background-color: rgb(93, 95, 255);
  }
}
.bannerConcurso {
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    width: 100%;
    border-radius: 0px;
  }
}
.btnContribuicao {
  text-transform: capitalize;
  margin: 0 auto;
}
.containCopy {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
}
.urlDesign {
  font-size: 12px;
  padding: 6px;
  //background-color: rgba(0, 20, 100, 0.1);
  background-color: rgba(55, 68, 126, 0.1);
  color: rgba(55, 68, 126, 1);
  border-radius: 5px;
  font-family: "monospace";
  display: inline-block;
}
.cardInfo {
  padding: 20px;
  //background-color: rgba(0, 80, 250, 0.1);
  background-color: rgba(55, 68, 126, 0.1);
  margin: 20px;
  margin-top: 0px;
  border-radius: 5px;
}
.tituloCopiaLink {
  font-size: 14px;
  padding: 4px;
  text-rendering: geometricPrecision;
  //color: rgb(0, 80, 130);
  color: rgba(55, 68, 126, 1);
  margin-bottom: 5px;
}
.btnCopy {
  font-size: 14px;
  padding: 4px 18px;
  //background-color: rgba(0, 80, 200, 1);
  background-color: rgba(55, 68, 126, 1);
  border-radius: 6px;
  text-transform: uppercase;
  color: white;
}
.saldoTitulo {
  font-weight: 400;
  font-family: "Poppins";
  color: rgba(100, 100, 100, 1);
  font-size: 14px;
}
.saldoValor {
  font-weight: 500;
  font-size: 20px;
  font-family: "Poppins";
}
.cardBilhetes {
  padding: 10px;
  margin: 20px;
  background-color: white;
  @media screen {
  }
}
.containHeader {
  display: flex;
  flex-direction: row;
}
</style>
