<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="pa-1">
      <div class="d-grid grid-col-auto-1">
        <div class="d-inline-block mx-1">
          <v-icon
            :color="getColorStatus(item.status)"
            class="pa-2"
            :title="getLegendaStatus(item.status)"
          >
            mdi-circle
          </v-icon>
        </div>
        <div
          class="d-inline-block mx-1 font-weight-bold text-body-1 text-left py-2 cor-text-header text-wrap"
        >
          {{ item.descricao }}
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="grey lighten-5 pa-0 ma-0">
      <div style="border-top: 1px solid #e0e0e0"></div>
      <div class="adicionarAnexo pa-2 text-center">
        <div style="display: flex; flex-direction: row;">
          <v-btn
            @click="abrirAnexo(item)"
            text
            color=""
            class="cor-valor"
            append-icon="mdi-paperclip"
          >
            <v-icon size="20">
              mdi-paperclip
            </v-icon>
            <span class="ml-1 font-weight-light">
              anexos
            </span>
          </v-btn>
          <v-btn
            @click="reprovarSolicitacao(item)"
            text
            :loading="loadingCancelar"
            color="red lighten-1"
            class="ml-1"
            v-if="isAdmin && !['PAGO'].includes(item.status)"
          >
            <v-icon class="ml-1">
              mdi-cancel
            </v-icon>
            <span class="ml-1 font-weight-light">
              Cancelar
            </span>
          </v-btn>
        </div>
        <!-- <v-row justify="center" class="text-center">
          <v-col
            cols="12"
            :sm="isAdmin && !['PAGO'].includes(item.status) ? 6 : 12"
          >
          </v-col>
        </v-row> -->
      </div>
      <div id="fornecedores">
        <div class="mt-4 ml-1 mb-2">
          <div class="font-weight-bold text-body-1">Fornecedor</div>
        </div>
        <div class="infoGrid">
          <div>
            <div class="labelTitle">Fornecedor</div>
            <div class="labelValue">{{ item.fornecedor.nome }}</div>
          </div>
          <div>
            <div class="labelTitle">Razão Social</div>
            <div class="labelValue">{{ item.fornecedor.razaoSocial }}</div>
          </div>
          <div>
            <div class="labelTitle">CPF/CNPJ</div>
            <div class="labelValue">{{ item.fornecedor.cpfCnpj }}</div>
          </div>
          <div>
            <div class="labelTitle">Chave Pix</div>
            <div class="labelValue">{{ item.fornecedor.chavePix }}</div>
          </div>
          <div>
            <div class="labelTitle">Titular da Conta</div>
            <div class="labelValue">{{ item.fornecedor.titularConta }}</div>
          </div>
          <div>
            <div class="labelTitle">Banco</div>
            <div class="labelValue">
              {{ item.fornecedor.banco }}
            </div>
          </div>
          <div>
            <div class="labelTitle">Agência - digito</div>
            <div class="labelValue">
              {{ item.fornecedor.agencia }} -
              {{ item.fornecedor.agenciaDigito }}
            </div>
          </div>
          <div>
            <div class="labelTitle">Conta</div>
            <div class="labelValue">
              {{ item.fornecedor.conta }} - {{ item.fornecedor.contaDigito }}
            </div>
          </div>
          <div>
            <div class="labelTitle"></div>
            <div class="labelValue"></div>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
      <div id="infoPagamentos">
        <div class="mt-3 ml-1 mb-2">
          <div class="font-weight-bold text-body-1">
            Informação de Pagamento
          </div>
        </div>
        <div class="infoGrid">
          <div>
            <div class="labelTitle">Pagar até dia</div>
            <div class="labelValue">
              {{ item.dataPagamento | dataFormate }}
            </div>
          </div>
          <div>
            <div class="labelTitle">Valor</div>
            <div class="labelValue">
              {{ item.valor | currency }}
            </div>
          </div>
          <div>
            <div class="labelTitle">Status da solicitação</div>
            <div class="labelValue">
              <v-chip outlined :color="getColorStatus(item.status)">
                {{ getLegendaStatus(item.status) }}
              </v-chip>
            </div>
          </div>
        </div>
      </div>
      <div class="my-2">
        <votesOptions :item="item" :loadingVotacao="loadingVotacao" />
      </div>
      <div>
        <!-- VOTOS -->
        <div class="d-block justify-left align-content-start fill-height">
          <div style="border-top: 1px solid #e0e0e0"></div>
          <div
            v-if="item.votos.length === 0"
            class="pa-2"
            style="color: rgba(150,150,150,1);"
          >
            Não houve votos até o momento
          </div>
          <v-row class="ma-0 pa-0" v-if="item.votos.length > 0">
            <v-col cols="12" sm="7" md="6" class="order-2 order-sm-1">
              <div class="votos text-center text-sm-left">
                <div class="pb-2 font-weight-bold">
                  Votos
                </div>
                <div id="votosCards" v-if="item.votos.length > 0">
                  <v-card
                    :key="index2"
                    v-for="(itemVoto, index2) in item.votos"
                    width="285"
                    class="elevation-0 grey lighten-4 mt-2 d-sm-block d-inline-block"
                  >
                    <div
                      style="display: flex; flex-direction: row; padding: 6px; margin: 4px;justify-items: center; align-items: center;"
                    >
                      <div class="pa-2 pr-5">
                        <v-icon
                          v-if="itemVoto.voto == 'SIM'"
                          color="#32d280"
                          size="20"
                          >mdi-thumb-up</v-icon
                        >
                        <v-icon
                          size="20"
                          v-if="itemVoto.voto == 'NAO'"
                          color="#d23732"
                          >mdi-thumb-down</v-icon
                        >
                      </div>
                      <div>
                        <v-avatar
                          size="30"
                          color="grey lighten-2"
                          class="white--text"
                        >
                          <v-img :src="itemVoto.fotoUrl"></v-img>
                        </v-avatar>
                      </div>
                      <div
                        style="margin-left: 8px; font-size: 14px; font-weight: 300"
                      >
                        {{ itemVoto.usuarioNome }}
                      </div>
                    </div>
                    <!-- <v-row class="pa-0 ma-0" justify="center">
                      <v-col cols="4" sm="4" class="text-left">
                        <v-avatar
                          size="75"
                          color="grey lighten-2"
                          class="white--text"
                        >
                          <v-img :src="itemVoto.fotoUrl"></v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="8" sm="8" class="text-center">
                        <div
                          class="text-body-1 py-2 font-weight-normal text-truncate"
                        >
                          {{ itemVoto.usuarioNome }}
                        </div>
                        <div class="text-body-2">
                          <v-icon
                            v-if="itemVoto.voto == 'SIM'"
                            color="#32d280"
                            size="35"
                            >mdi-thumb-up</v-icon
                          >
                          <v-icon
                            size="35"
                            v-if="itemVoto.voto == 'NAO'"
                            color="#d23732"
                            >mdi-thumb-down</v-icon
                          >
                        </div>
                      </v-col>
                    </v-row> -->
                  </v-card>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="5" md="6" class="order-1 order-sm-2">
              <div class="analise">
                <div class="pa-2 text-center text-sm-left font-weight-bold">
                  Contagem
                </div>
                <div class="likes">
                  <div class="pa-2 text-center text-sm-left">
                    <div class="d-inline-grid">
                      <v-icon size="20" color="#32d280" class="cor-valor"
                        >mdi-thumb-up</v-icon
                      >
                    </div>
                    <div class="d-inline-grid ml-2">
                      <div class="labelVoteValue">
                        {{ item.percentualVotoSim }}%
                      </div>
                    </div>
                  </div>
                  <div class="pa-2 text-center text-sm-left">
                    <div class="d-inline-grid">
                      <v-icon size="20" color="#d23732" class="cor-valor"
                        >mdi-thumb-down</v-icon
                      >
                    </div>
                    <div class="d-inline-grid ml-2">
                      <div class="labelVoteValue">
                        {{ item.percentualVotoNao }}%
                      </div>
                    </div>
                  </div>
                  <div class="pa-2 text-center text-sm-left">
                    <div class="d-inline-grid">
                      <v-icon size="20" class="cor-valor"
                        >mdi-account-question</v-icon
                      >
                    </div>
                    <div class="d-inline-grid ml-2">
                      <div class="labelVoteValue">
                        {{ item.percentualVotoPendente }}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import {
  GetColorStatus,
  GetTextStatus
} from "@/constants/statusSolicitationPayment";
import serviceSolicitacoes from "@/middleware/solicitacoesDePagamento";
import votesOptions from "./fragments/votesOptions.vue";
export default {
  name: "itemSolicitation",
  components: {
    votesOptions
  },
  props: {
    item: Object
  },
  data() {
    return {
      loadingVotacao: false,
      loadingCancelar: false
    };
  },
  computed: {
    isCommission() {
      if (this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "COMISSAO") {
        return true;
      } else {
        return false;
      }
    },
    isAdmin() {
      return (
        this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "ADMIN" ||
        this.$store.getters["aluno/userIsAdmin"]
      );
    }
  },
  methods: {
    reprovarSolicitacao(solicitacao) {
      this.loadingCancelar = true;
      serviceSolicitacoes
        .cancelarSolicitacao(solicitacao.id)
        .then(resp => {
          this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
          this.loadingCancelar = false;
          this.$root.infoModal(resp.data);
        })
        .catch(err => {
          this.loadingCancelar = false;
          this.$root.errorModal(err.data);
          console.error(err);
        });
    },
    abrirAnexo(item) {
      this.$emit("abrirAnexo", item);
    },
    existeDadosFornecedor(dados) {
      if (dados.fornecedor && dados.fornecedor.nome) {
        return dados.fornecedor.nome;
      } else if (dados.destino) {
        return dados.destino;
      } else {
        return "";
      }
    },
    getLegendaStatus(val) {
      return GetTextStatus(val);
    },
    getColorStatus(val) {
      return GetColorStatus(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.labelVoteValue {
  font-weight: 400;
  font-size: 16px;
}
.customBtn {
  color: red;
  background-color: rgba(200, 0, 0, 0.05);
  border-radius: 4px;
  padding: 8px 15px;
}
.infoGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  & > div {
    margin: 5px;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}
.labelTitle {
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  color: rgba(140, 140, 140, 1);
}
.labelValue {
  font-weight: 400;
  font-size: 15px;
}
</style>
