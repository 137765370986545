const mainList = [
  {
    value: "AGUARDANDO_AUTORIZACAO_COMISSAO",
    text: "Aguardando autorização da comissão",
    color: "#6C5CE7"
  },
  {
    value: "AGUARDANDO_ANALISE",
    text: "Em analise",
    color: "#FFD700"
  },
  {
    value: "AUTORIZADO",
    text: "Aprovado pela comissão",
    color: "#0984E3"
  },
  {
    value: "RECUSADO_PELA_COMISSAO",
    text: "Recusado pela comissão",
    color: "#E74C3C"
  },
  {
    value: "RECUSADO_PELA_PLATAFORMA",
    text: "Recusado pela plataforma",
    color: "#E74C3C"
  },
  {
    value: "RECUSADO_SEM_SALDO",
    text: "Recusado por saldo",
    color: "#E74C3C"
  },
  {
    value: "PAGO",
    text: "Pago",
    color: "#28C76F"
  },
  {
    value: "CANCELADO",
    text: "Cancelado",
    color: "#B2BEC3"
  },
  {
    value: "EMPATE",
    text: "Empate",
    color: "#FFA801"
  }
];

function GetColorStatus(val) {
  return mainList.find(item => item.value === val).color;
}

function GetTextStatus(val) {
  return mainList.find(item => item.value === val).text;
}

export { GetColorStatus, GetTextStatus, mainList };
