<template>
  <div>
    <div
      class="text-center text-sm-left text-h5 text-sm-h5 font-weight-bold primaryDash--text pa-3 pa-sm-5 justify-center"
    >
      <div class="d-inline-block">
        Pagamentos a fornecedores
      </div>
    </div>

    <div class="pa-2 ma-2 text-center text-sm-left">
      <v-btn
        color="secondaryDash"
        class="white--text"
        @click="abrirModalSelectSolicitacao()"
        depressed
        v-if="isAdmin"
      >
        Nova Solicitação
      </v-btn>
    </div>

    <div class="lista ma-0 ma-sm-2">
      <v-row justify="center" align="center">
        <v-col cols="12" sm="6" class="text-center text-sm-left">
          <div
            class="text-body-1 py-2 grey--text text--darken-1 d-inline-grid grid-col-auto-1 text-left"
          >
            <v-icon class="px-1">mdi-view-list-outline</v-icon>
            <div>
              Solicitações
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="py-2 text-center">
            <v-btn
              @click="abrirModalLegenda()"
              depressed
              color="white"
              class="text-body-1 lowShadow d-inline-grid grid-col-auto-1 justify-center align-center"
            >
              <v-icon class="px-1">mdi-information-outline</v-icon>
              <div>Legendas</div>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <v-expansion-panels accordion class="elevation-0 lowShadow">
        <vItemSolicitation
          v-for="(item, index) in getSolicitacoes"
          :key="index"
          :item="item"
          @abrirAnexo="abrirAnexoSolicitacao"
        />
      </v-expansion-panels>
    </div>
    <vGuiaLegendas v-if="modalGuiaLegendas" @close="closeModalLegenda" />
    <vAnexosDaSolicitacao
      @close="fecharAnexoSolicitacao"
      :solicitacao="itemSolicitacaoAnexo"
      v-if="modalAnexoSolicitacao"
    />
    <vNovaSolicitacao
      @close="closeModalSolicitacao"
      v-if="modalNovaSolicitacao"
    />
    <vNovaSolicitacaoInterna
      @close="closeModalSolicitacaoInterna"
      v-if="modalNovaSolicitacaoInterna"
    />
    <vSelecionaTipoDeSolicitacao
      v-if="modalTipoSolicitacao"
      @selecionaTipo="selecionaTipoSolicitacao"
      @close="modalTipoSolicitacao = false"
    />
  </div>
</template>

<script>
import vGuiaLegendas from "./guiaStatus";
import vItemSolicitation from "./itemSolicitation";
import vAnexosDaSolicitacao from "./modalAnexosDaSolicitacao";
import vNovaSolicitacao from "./novaSolicitacao";
import vNovaSolicitacaoInterna from "./novaSolicitacaoInterna.vue";
import vSelecionaTipoDeSolicitacao from "./vSelecionaTipoDeSolicitacao.vue";

export default {
  components: {
    vNovaSolicitacao,
    vItemSolicitation,
    vNovaSolicitacaoInterna,
    vGuiaLegendas,
    vAnexosDaSolicitacao,
    vSelecionaTipoDeSolicitacao
  },
  data() {
    return {
      modalNovaSolicitacaoInterna: false,
      modalTipoSolicitacao: false,
      modalAnexoSolicitacao: false,
      loadingVotacao: false,
      modalGuiaLegendas: false,
      modalNovaSolicitacao: false,
      itemSolicitacaoAnexo: {},
      loadingCancelar: false
    };
  },
  mounted() {
    this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
  },
  methods: {
    existeDadosFornecedor(dados) {
      if (dados.fornecedor && dados.fornecedor.nome) {
        return dados.fornecedor.nome;
      } else if (dados.destino) {
        return dados.destino;
      } else {
        return "";
      }
    },
    closeModalSolicitacaoInterna() {
      this.modalNovaSolicitacaoInterna = false;
    },
    abrirModalSelectSolicitacao() {
      this.modalTipoSolicitacao = true;
    },
    selecionaTipoSolicitacao(tipo) {
      this.modalTipoSolicitacao = false;
      if (tipo === "evento") {
        this.modalNovaSolicitacaoInterna = true;
      } else if (tipo === "fornecedor") {
        this.modalNovaSolicitacao = true;
      } else {
        this.$root.errorModal("TIPO de solicitação não implementada");
      }
    },

    fecharAnexoSolicitacao() {
      this.modalAnexoSolicitacao = false;
      this.itemSolicitacaoAnexo = {};
    },
    abrirAnexoSolicitacao(item) {
      this.itemSolicitacaoAnexo = item;
      this.modalAnexoSolicitacao = true;
    },
    abrirModalLegenda() {
      this.modalGuiaLegendas = true;
    },
    closeModalLegenda() {
      this.modalGuiaLegendas = false;
    },
    closeModalSolicitacao() {
      this.modalNovaSolicitacao = false;
    }
    // votarSIM(solicitacaoID) {
    //   this.loadingVotacao = true;
    //   serviceSolicitacoes
    //     .setVotoSIM(solicitacaoID)
    //     .then(this.votoConfirmado)
    //     .catch(this.votoError);
    // },
    // votarNAO(solicitacaoID) {
    //   this.loadingVotacao = true;
    //   solicitacaoID;
    //   serviceSolicitacoes
    //     .setVotoNAO(solicitacaoID)
    //     .then(this.votoConfirmado)
    //     .catch(this.votoError);
    // },
    // votoError(err) {
    //   this.loadingVotacao = false;
    //   this.$root.errorModal(err.data);
    //   console.error(err);
    // },
    // votoConfirmado() {
    //   this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
    //   this.$root.sucessoModal("Voto efetuado com sucesso!");
    //   this.loadingVotacao = false;
    // }
  },
  filters: {
    hasData(val) {
      if (val) {
        return val;
      } else {
        return "S/ REGISTRO";
      }
    }
  },
  computed: {
    getSolicitacoes() {
      return this.$store.getters["aluno/getSolicitacoesDePagamento"];
    },
    isCommission() {
      if (this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "COMISSAO") {
        return true;
      } else {
        return false;
      }
    },
    isAdmin() {
      return (
        this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "ADMIN" ||
        this.$store.getters["aluno/userIsAdmin"]
      );
    }
  }
};
</script>

<style lang="scss" scoped>
$likeColor: #32d280;
$unlikeColor: #d23732;
$expansion-panel-content-padding: 0px;

.cor-text-header {
  color: #5e5873;
}
.cor-valor {
  color: #5e5873;
}
.v-expansion-panel-content__wrap {
  padding: 0px;
}
.votos {
  //overflow-y: auto;
  //overflow-x: hidden;
  .votosCards {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
  }
}
</style>
