<template>
  <div>
    <v-menu v-if="permissaoDeAcessoAoMenu" offset-y min-width="auto" left>
      <template v-slot:activator="{ on, attrs }">
        <div style="text-align:right;">
          <v-btn white icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </div>
      </template>
      <div style="width: 100%; background-color:white;">
        <div
          @click="$emit('abrirContribuicoes')"
          v-if="getPermissaoUserAuth == 'ADMIN'"
          class="itemSelectMenu"
        >
          Contribuições
        </div>
        <div class="itemSelectMenu" @click="abrirModalConfirm('reenviarEmail')">
          Reenviar convite
        </div>
        <div
          class="itemSelectMenu"
          @click="abrirModalConfirm('setComissao')"
          v-if="
            getParticipanteRole !== 'COMISSAO' &&
              getParticipanteRole !== 'ADMIN' &&
              getParticipanteConvite == 'AUTORIZADO'
          "
        >
          Adicionar comissário
        </div>
        <div
          class="itemSelectMenu"
          @click="abrirModalConfirm('setAdmin')"
          v-if="
            getParticipanteRole !== 'COMISSAO' &&
              getParticipanteRole !== 'ADMIN' &&
              getParticipanteConvite == 'AUTORIZADO'
          "
        >
          Adicionar administrador
        </div>
        <div
          class="itemSelectMenu"
          @click="abrirModalConfirm('aceitarAluno')"
          v-if="
            getParticipanteConvite == 'NAO_AUTORIZADO' ||
              getParticipanteConvite == 'PENDENTE'
          "
        >
          Ativar Participante
        </div>
        <div class="lineMenu"></div>
        <div
          class="itemSelectMenu"
          @click="abrirModalConfirm('removeComissao')"
          v-if="getParticipanteRole == 'COMISSAO'"
        >
          Remover comissário
        </div>
        <div
          class="itemSelectMenu"
          @click="abrirModalConfirm('desativarAluno')"
          v-if="getParticipanteConvite == 'AUTORIZADO'"
        >
          Desativar usuário
        </div>
        <div
          class="itemSelectMenu"
          @click="abrirModalConfirm('cancelarContrato')"
          v-if="getPermissaoUserAuth == 'ADMIN'"
        >
          Cancelar contrato
        </div>
        <div
          class="itemSelectMenu"
          @click="abrirModalConfirm('excluirParticipante')"
          v-if="permissaoParaExcluirParticipante"
        >
          Excluir Participante
        </div>
      </div>
    </v-menu>
    <v-dialog v-model="dialogConfirm" max-width="400" persistent>
      <v-card class="pa-4">
        <div>{{ mensagemModal }}</div>
        <div
          class="grid grid-cols-2 mt-5"
          style="justify-items: center; align-items: center;"
        >
          <div>
            <v-btn color="white" depressed @click="closeModalConfirm()">
              Cancelar
            </v-btn>
          </div>
          <div>
            <v-btn color="red" dark depressed @click="confirmAcao()">
              Confirmar
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// create modal
import serviceTurma from "@/middleware/turmaController";
export default {
  props: {
    participanteConvite: {
      type: String,
      required: true
    },
    participanteRole: {
      type: String,
      required: true
    },
    souEsseAluno: {
      type: Boolean,
      required: true
    },
    participanteId: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogConfirm: false,
      mensagemModal: "Tem certeza que deseja executar essa ação?",
      metodo: null,
      loading: false
    };
  },
  methods: {
    closeModalConfirm() {
      this.dialogConfirm = false;
      this.metodo = null;
    },
    abrirModalConfirm(metodo) {
      this.metodo = metodo;
      this.generateTextModal();
      this.dialogConfirm = true;
    },
    generateTextModal() {
      const method = this.metodo;
      switch (method) {
        case "excluirParticipante":
          this.mensagemModal =
            "Tem certeza que gostaria de excluir esse participante?";
          break;
        case "aceitarAluno":
          this.mensagemModal =
            "Tem certeza que gostaria de aceitar esse aluno?";
          break;
        case "cancelarContrato":
          this.mensagemModal =
            "Tem certeza que gostaria de cancelar esse contrato?";
          break;
        case "reenviarEmail":
          this.mensagemModal =
            "Tem certeza que gostaria de reenviar esse email?";
          break;
        case "desativarAluno":
          this.mensagemModal =
            "Tem certeza que gostaria de desativar esse aluno?";
          break;
        case "setComissao":
          this.mensagemModal =
            "Tem certeza que gostaria de adicionar essa pessoa como comissão?";
          break;
        case "setAdmin":
          this.mensagemModal =
            "Tem certeza que gostaria de adicionar essa pessoa como administrador?";
          break;
        case "removeComissao":
          this.mensagemModal =
            "Tem certeza que gostaria de remover essa pessoa da comissão?";
          break;
        default:
          this.loading = false;
          break;
      }
    },
    confirmAcao() {
      this.dialogConfirm = false;
      this.loading = true;
      const method = this.metodo;
      switch (method) {
        case "excluirParticipante":
          this.excluirParticipante();
          break;
        case "aceitarAluno":
          this.aceitarAluno();
          break;
        case "cancelarContrato":
          this.cancelarContrato();
          break;
        case "reenviarEmail":
          this.reenviarEmail();
          break;
        case "desativarAluno":
          this.desativarAluno();
          break;
        case "setComissao":
          this.setComissao();
          break;
        case "setAdmin":
          this.setAdmin();
          break;
        case "removeComissao":
          this.removeComissao();
          break;
        default:
          this.loading = false;
          break;
      }
    },
    reenviarEmail() {
      serviceTurma
        .reenviarConvite(this.item.email)
        .then(resp => {
          this.$root.infoModal("Convite reenviado com sucesso");
          console.log(resp.data);
        })
        .catch(err => {
          console.error(err.data);
          this.$root.errorModal(err.data);
        });
    },
    excluirParticipante() {
      this.$emit("confirmarExclusaoParticipante", this.participanteId);
    },
    atualizaParticipantesDaLista() {
      this.$store.dispatch("aluno/REQUEST_ALUNOS_DA_TURMA").catch(err => {
        this.$root.errorModal(err.data);
      });
    },
    aceitarAluno() {
      serviceTurma
        .aceitaAluno(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Aluno aceito com sucesso.");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    desativarAluno() {
      serviceTurma
        .desativaAluno(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Aluno bloqueado com sucesso.");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    setAdmin() {
      serviceTurma
        .nomearAlunoAdmin(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Alteração feita com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    removeComissao() {
      serviceTurma
        .removerAlunoComissao(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Alterado com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    },
    setComissao() {
      serviceTurma
        .addAlunoComissao(this.getTurmaAtiva, this.participanteId)
        .then(() => {
          this.$root.infoModal("Alteração feita com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
          console.log(err);
        });
    },
    cancelarContrato() {
      serviceTurma
        .cancelarContrato(this.participanteId)
        .then(() => {
          this.$root.infoModal("Contrato cancelado com sucesso");
          this.atualizaParticipantesDaLista();
        })
        .catch(err => {
          this.$root.errorModal(err.data);
        });
    }
  },
  computed: {
    permissaoParaExcluirParticipante() {
      if (this.$store.getters["aluno/userIsAdmin"]) {
        return true;
      } else if (this.getPermissaoUserAuth == "ADMIN") {
        return true;
      } else {
        return false;
      }
    },
    permissaoDeAcessoAoMenu() {
      if (this.$store.getters["aluno/userIsAdmin"]) {
        return true;
      } else if (this.getPermissaoUserAuth == "ADMIN") {
        return true;
      } else {
        return false;
      }
    },
    getTurmaAtiva() {
      return this.$store.getters["aluno/getTurmaAtiva"];
    },
    getParticipanteRole() {
      return this.participanteRole;
    },
    getParticipanteConvite() {
      return this.participanteConvite;
    },
    getPermissaoUserAuth() {
      return this.$store.getters["aluno/getPermissaoTurmaAtiva"];
    }
  }
};
</script>

<style lang="scss" scoped>
.lineMenu {
  //width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 4px 0px;
}
.itemSelectMenu {
  padding: 15px;
  background-color: white;
  cursor: pointer;
  font-size: 15px;
  &:hover {
    background-color: rgba(50, 50, 50, 0.1);
  }
}
</style>
