<template>
  <div class="votos">
    <v-row
      no-gutters
      class="mx-1"
      v-if="isCommission && getPermissaoVotoStatus(item.status)"
    >
      <v-col cols="12" sm="6" class="pa-1 text-center text-sm-right">
        <v-btn
          color="grey lighten-3 elevation-0"
          @click="votarSIM(item.id)"
          large
          :loading="loadingVotacao"
        >
          <v-icon size="25" color="#32d280">mdi-thumb-up</v-icon>
          <span class="ml-2">
            APROVAR
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="pa-1 text-center text-sm-left">
        <v-btn
          color="grey lighten-3 elevation-0"
          @click="votarNAO(item.id)"
          :loading="loadingVotacao"
          large
        >
          <v-icon size="25" color="#d23732">mdi-thumb-down</v-icon>
          <span class="ml-2">
            REPROVAR
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="mx-1"
      v-if="isAdmin && ['EMPATE'].includes(item.status)"
    >
      <v-col cols="12" sm="6" class="pa-1 text-center text-sm-right">
        <v-btn
          color="grey lighten-3 elevation-0"
          @click="votarSIM(item.id)"
          large
          :loading="loadingVotacao"
        >
          <v-icon size="25" color="#32d280">mdi-gavel</v-icon>
          <span class="ml-2">
            APROVAR
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" class="pa-1 text-center text-sm-left">
        <v-btn
          color="grey lighten-3 elevation-0"
          @click="votarNAO(item.id)"
          :loading="loadingVotacao"
          large
        >
          <v-icon size="25" color="#d23732">mdi-gavel</v-icon>
          <span class="ml-2">
            REPROVAR
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import serviceSolicitacoes from "@/middleware/solicitacoesDePagamento";

export default {
  props: ["item"],
  data() {
    return {
      loadingVotacao: false
    };
  },
  computed: {
    isCommission() {
      if (this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "COMISSAO") {
        return true;
      } else {
        return false;
      }
    },
    isAdmin() {
      return (
        this.$store.getters["aluno/getPermissaoTurmaAtiva"] === "ADMIN" ||
        this.$store.getters["aluno/userIsAdmin"]
      );
    }
  },
  methods: {
    getPermissaoVotoStatus(statusName) {
      console.log(statusName);
      if (statusName === "AGUARDANDO_AUTORIZACAO_COMISSAO") {
        return true;
      } else if (statusName === "AUTORIZADO") {
        return true;
      } else {
        return false;
      }
    },
    votarSIM(solicitacaoID) {
      this.loadingVotacao = true;
      serviceSolicitacoes
        .setVotoSIM(solicitacaoID)
        .then(() => {
          this.votoConfirmado();
        })
        .catch(err => {
          this.votoError(err);
        });
    },
    votarNAO(solicitacaoID) {
      this.loadingVotacao = true;
      serviceSolicitacoes
        .setVotoNAO(solicitacaoID)
        .then(() => {
          this.votoConfirmado();
        })
        .catch(err => {
          this.votoError(err);
        });
    },
    votoError(err) {
      this.loadingVotacao = false;
      this.$root.errorModal(err.data);
      console.error(err);
    },
    votoConfirmado() {
      this.$store.dispatch("aluno/REQUEST_SOLICITACOES_DE_PAGAMENTO");
      this.$root.sucessoModal("Voto efetuado com sucesso!");
      this.loadingVotacao = false;
    }
  }
};
</script>
